import * as React from "react"
import SectionEntryTitle from './SectionEntryTitle';
import SectionEntryContent from './SectionEntryContent';
import './SectionEntry.scss';

const SectionEntry = (props) => {
  const containerStyles = {
    ...props?.containerStyles || {},
  };

  return (
    <div className="section-entry" style={containerStyles}>
      {React.Children.toArray(props.children).find(({ type }) => type === SectionEntryTitle)}

      {React.Children.toArray(props.children).find(({ type }) => type === SectionEntryContent)}
    </div>
  )
}

export default SectionEntry;