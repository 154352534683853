import React from "react"
import { graphql } from "gatsby"
import Container from '../../components/Container'
import IndentedContent from '../../components/helpers/IndentedContent'
import ContentPage from '../../components/layout/ContentPage'
import SectionEntry from '../../components/SectionEntry'
import SectionEntryContent from '../../components/SectionEntryContent'
import SectionEntryTitle from '../../components/SectionEntryTitle'
import SEO from '../../components/seo'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <main>
      <SEO title={frontmatter.title} />
      
      <Container containerStyles={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, right: 0, height: '700px', backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}>
      </Container>

      <ContentPage>
        <Container>
          <IndentedContent>
            <SectionEntry>
              <SectionEntryTitle>{frontmatter.title}</SectionEntryTitle>
              <SectionEntryContent>{frontmatter.date}</SectionEntryContent>
            </SectionEntry>
          
            <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          </IndentedContent>
        </Container>
      </ContentPage>
    </main>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`