import * as React from "react"

const SectionEntryTitle = (props) => {
  return (
    <h2 style={{textAlign: 'center'}}>
      {props.children}
    </h2>
  )
}

export default SectionEntryTitle;