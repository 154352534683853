import * as React from "react"
import './SectionEntryContent.scss';

const SectionEntryContent = (props) => {
  return (
    <div className="section-entry-content">
      {props.children}
    </div>
  )
}

export default SectionEntryContent;